import React, { useRef } from 'react'
import {
  Box,
  Container,
  Flex,
  HStack,
  Image,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { Footer } from '../../components/Footer/footer'
import { useNavigate } from 'react-router-dom'
import { PingTest } from './pingTest'
import Chart from 'chart.js/auto'

export const Test = () => {
  const navigate = useNavigate()
  const chartInstanceRef = useRef<Chart | null>(null)

  return (
    <Flex flexDirection="column" flex="1">
      <Flex
        alignItems="center"
        h="72px"
        bg="bg-surface"
        boxShadow={useColorModeValue('sm', 'sm-dark')}
      >
        <Container maxW="full">
          <Flex alignItems="center" justify="space-between">
            <HStack spacing="4">
              <Image
                src="/logo.svg"
                onClick={() => navigate('/')}
                w="2.5em"
                data-qa__id="navbar_logo"
              />
            </HStack>
          </Flex>
        </Container>
      </Flex>
      <Container
        p={{ base: 1, md: 4 }}
        maxW="100%"
        flex="1"
        display="flex"
        alignItems="center"
        bg="var(--chakra-colors-bg-canvas)"
      >
        <Flex
          p={{ base: '0', sm: '6' }}
          borderRadius="var(--chakra-radii-lg)"
          boxShadow="var(--chakra-shadows-sm)"
          bg="whiteAlpha.900"
          direction="column"
          w="full"
          h={`calc(100vh - 92px - 63px - 22px)`}
          alignItems="center"
          pt="4"
        >
          <Box w="100%">
            <Text pb="3" fontSize="xl" fontWeight="medium">
              Connection Test
            </Text>
          </Box>
          <Box w="100%">
            <PingTest chartInstanceRef={chartInstanceRef} />
          </Box>
        </Flex>
      </Container>
      <Footer isLogged={true} />
    </Flex>
  )
}
