import React, { useEffect, useRef, useState } from 'react'
import { Box } from '@chakra-ui/react'
import { IAudios } from '../audio.utils'

export const SimpleSessionPlayer = ({
  audios,
  selectedAudio,
  setLoader,
  setSelectedAudio,
  setProgress,
}: {
  audios: IAudios[]
  setLoader: React.Dispatch<React.SetStateAction<boolean>>
  selectedAudio: IAudios | null
  setSelectedAudio: React.Dispatch<React.SetStateAction<IAudios | null>>
  setProgress: React.Dispatch<
    React.SetStateAction<{ progress: number; duration: number }>
  >
}) => {
  const audioRef = useRef<HTMLAudioElement | null>(null)
  const preloadedAudiosRef = useRef<HTMLAudioElement[]>([])
  const timeParameter = parseInt(
    new URLSearchParams(location.search).get('t') || '0',
    10,
  )
  const audioParameter = new URLSearchParams(location.search).get('id')

  useEffect(() => {
    if (audioParameter) {
      const newAudio = audios.find(({ id }) => id === audioParameter)
      if (newAudio) {
        setSelectedAudio(newAudio)
      }
    }
  }, [])

  useEffect(() => {
    preloadedAudiosRef.current = audios.map((audio) => {
      const audioElement = new Audio(audio.url)
      audioElement.preload = 'auto'
      audioElement.load()
      return audioElement
    })
  }, [audios])

  useEffect(() => {
    if (audioRef.current && selectedAudio) {
      const currentTime = audioRef.current.currentTime
      const isPlaying = !audioRef.current.paused

      audioRef.current.src = selectedAudio.url
      audioRef.current.currentTime = currentTime

      audioRef.current.load()
      if (isPlaying) {
        audioRef.current.play()
      }
    }
  }, [selectedAudio])

  useEffect(() => {
    const audioPlayer = audioRef.current
    if (audioPlayer) {
      const style = document.createElement('style')
      style.innerHTML = `
        audio::-webkit-media-controls-volume-control-container,
        audio::-webkit-media-controls-mute-button {
          display: none !important;
        }
        audio::-moz-volume-control {
          display: none !important;
        }
      `
      document.head.appendChild(style)
      return () => {
        document.head.removeChild(style)
      }
    }
  }, [])

  const [isFirstLoad, setIsFirstLoad] = useState(true)

  return selectedAudio ? (
    <Box w="100%">
      <audio
        id="simple_audio"
        onLoadedDataCapture={() => {
          setLoader(false)
        }}
        onTimeUpdateCapture={(event: any) => {
          const currentTime = event.target.currentTime
          const duration = event.target.duration
          setProgress({
            duration: duration,
            progress: currentTime / duration,
          })
        }}
        onLoadedData={() => {
          if (isFirstLoad && audioRef?.current && timeParameter) {
            setIsFirstLoad(false)
            audioRef.current.currentTime = timeParameter / 1000
          }
        }}
        ref={audioRef}
        style={{ width: '100%' }}
        controls
        controlsList="nodownload noremoteplayback noplaybackrate"
        preload="auto"
      >
        <source src={selectedAudio.url} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    </Box>
  ) : null
}
