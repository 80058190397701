import {
  Box,
  Button,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useToast,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  Stepper,
  useSteps,
  Link,
  AlertIcon,
  Alert,
  useMediaQuery,
} from '@chakra-ui/react'
import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import {
  DevicesController,
  userHasVirtualMicrophone,
} from '../DevicesController/devicesController'
import { useAppDispatch, useAppSelector } from '../../utils/reduxUtils'
import { Survey } from '../Survey/survey'
import { SingleValue } from 'chakra-react-select'
import { saveQualitySurvey } from '../../features/qualitySurvey/actions'
import {
  clearSessionReduxData,
  getSession,
  setSession,
  updateGetterForSession,
} from '../../features/session/actions'
import { ISessionDetails } from '../../models/session/session'
import { setSelectedVoice } from '../../features/audio/actions'
import { IVoice } from '../../models/user/user'
import { newVoiceElements } from '../../pages/Live/live'
import { useAsyncDebounce } from 'react-table'
import { mobileBreakPoint } from '../../utils/mobileBreakPoint'
import { SessionComponent } from '../../pages/Session/sessionComponent/sessionComponent'
import data from '../../pages/DemoPage/empty_session.json'
import { getAudio } from '../../pages/Session/audio.utils'
import { sound } from '@pixi/sound'
import { setLiveSession } from '../../features/audioManagement/actions'

enum STEP_OPTIONS {
  START,
  DEVICE_CONTROL,
  TEST_CALL,
  CHECK_TEST,
  SURVEY,
}

let downloadTimeout: null | NodeJS.Timeout = null
export const stepsElements: any = ({
  isFirstLogin,
}: {
  isFirstLogin: boolean
}) => ({
  START: {
    description: 'Start',
    step: 0,
    header: isFirstLogin
      ? 'Welcome to Meaning for Contact Centers'
      : "Let's test your setup",
  },
  DEVICE_CONTROL: {
    description: 'Device Configuration',
    step: 1,
    header: 'Device Configuration',
  },
  TEST_CALL: {
    description: 'Record Sample',
    step: 2,
    header: 'Record Your Voice',
  },
  CHECK_TEST: {
    description: 'Verify',
    step: 3,
    header: 'Verify Your Setup',
  },
  SURVEY: {
    description: 'Done',
    step: 4,
    header: isFirstLogin ? "You're ready to go" : 'Setup Test Completed',
  },
})

export const DeviceTestModal = ({
  deviceTest,
  onClose,
  carousel,
  isFirstLogin,
}: {
  carousel: ReactNode
  deviceTest: {
    isOpen: boolean
  }
  isFirstLogin: boolean
  onClose: (withoutSavingChanges: boolean) => void
}) => {
  const { potential_issues, features, eula_url, is_eula_accepted } =
    useAppSelector((state) => state.user)
  const steps = stepsElements({
    isFirstLogin,
  })
  const isEulaFlow = is_eula_accepted && eula_url !== ''

  steps.START['prevStep'] = null
  steps.START['nextStep'] = steps.DEVICE_CONTROL

  steps.DEVICE_CONTROL['prevStep'] = isEulaFlow ? null : steps.START
  steps.DEVICE_CONTROL['nextStep'] = steps.TEST_CALL

  steps.TEST_CALL['prevStep'] = steps.DEVICE_CONTROL
  steps.TEST_CALL['nextStep'] = steps.CHECK_TEST

  steps.CHECK_TEST['prevStep'] = steps.TEST_CALL
  steps.CHECK_TEST['nextStep'] = steps.SURVEY

  steps.SURVEY['prevStep'] = steps.CHECK_TEST
  steps.SURVEY['nextStep'] = null

  const [deviceTestSteps, setDeviceTestSteps] = useState(steps.START)
  const {
    mediaState,
    selectedVoice,
    selectedDevices,
    areAudioPermissions,
    availableDevices,
  } = useAppSelector((state) => state.audio)
  const { liveSession } = useAppSelector((state) => state.audioManagement)

  const [isConnectedTest, setIsConnectedTest] = useState(false)
  const { isLoadingSession, session } = useAppSelector((state) => state.session)

  const [sessionTestId, setSessionTestId] = useState('')

  const [rating, setRating] = useState(0)
  const [issueDescription, setIssueDescription] = useState<string>('')
  const [selectedIssue, setSelectedIssue] = useState<SingleValue<any>>(null)
  const dispatch = useAppDispatch()
  const toast = useToast()
  const [isCountdownDone, setIsCountdownDone] = useState(false)
  const [counter, setCounter] = useState(0)
  const [intervalSet, setIntervalState] = useState<any>(null)
  let conversionTimeout: any = null

  const selectVoiceHandler = (voice: IVoice) => {
    if (mediaState.isUploading) {
      return
    }
    let newVoice = { ...voice }
    if (
      newVoice.voice_id === selectedVoice.voice?.voice_id &&
      newVoice.model_id === selectedVoice.voice?.model_id
    ) {
      newVoice = newVoiceElements()
    }
    dispatch(
      setSelectedVoice({
        voice: newVoice,
      }),
    )
  }

  useEffect(() => {
    if (counter >= 10 && !isCountdownDone) {
      setIsCountdownDone(true)
      selectVoiceHandler(selectedVoice.voice)
    }
  }, [counter])
  useEffect(() => {
    if (mediaState.isConnected && !isConnectedTest) {
      let counter = 0
      setCounter(counter)
      setIsCountdownDone(false)
      setIntervalState(
        setInterval(() => {
          counter = counter + 1
          setCounter(counter)
          if (counter >= 10 && intervalSet) {
            clearInterval(intervalSet)
          }
        }, 1000),
      )
    }
    if (!mediaState.isConnected) {
      if (intervalSet) {
        clearInterval(intervalSet)
      }
      setCounter(0)
    }
    if (isConnectedTest && !mediaState.isConnected && isCountdownDone) {
      setIsConnectedTest(false)
      setSessionTestId(liveSession.session_id)
    }
    setIsConnectedTest(mediaState.isConnected)
  }, [mediaState.isConnected, isCountdownDone])
  const onSubmit = (isSurvey: boolean) => {
    if (isSurvey) {
      const survey = {
        score: rating,
        answer: issueDescription,
        potential_issue_id: selectedIssue ? selectedIssue.value : false,
        session_id: sessionTestId,
      }
      dispatch(saveQualitySurvey({ data: survey, toast }))
    }

    setRating(0)
    setSelectedIssue(null)
    setIssueDescription('')
    setSessionTestId('')
    setDeviceTestSteps(steps.START)
    onClose(false)
  }
  const [audios, setAudios] = useState<
    {
      url: string
      name: string
      id: string
      description: string
      isConversion: boolean
    }[]
  >([])

  const isInOutputVirtualMicrophone = availableDevices.output_devices?.filter(
    ({ device_name }) =>
      device_name ? userHasVirtualMicrophone.test(device_name) : false,
  )

  const isVirtualMicrophoneInstalled = isInOutputVirtualMicrophone
    ? isInOutputVirtualMicrophone.length > 0
    : false

  const testedVirtualMicrophone = userHasVirtualMicrophone.test(
    selectedDevices.output_device?.device_name
      ? selectedDevices.output_device?.device_name
      : '',
  )
  const canCloseWithoutSavingChanges =
    deviceTestSteps.step === 1 && !testedVirtualMicrophone

  const closeModal = () => {
    setDeviceTestSteps(steps.START)
    onClose(canCloseWithoutSavingChanges)
  }

  const can_show_quality_survey = true
  const onNextStep = () => {
    if (conversionTimeout) {
      clearTimeout(conversionTimeout)
    }
    if (
      deviceTestSteps.step === 3 &&
      (potential_issues.length === 0 || !can_show_quality_survey)
    ) {
      onSubmit(false)
    } else {
      setDeviceTestSteps(deviceTestSteps.nextStep)
    }
  }
  const onPrevStep = () => {
    try {
      sound.removeAll()
    } catch (error) {
      console.warn('Failed to stop audio:', error)
    }
    dispatch(
      setLiveSession({
        ...liveSession,
        session_id: '',
      }),
    )
    dispatch(setSession({ session: {} }))
    setCounter(0)
    if (conversionTimeout) {
      clearTimeout(conversionTimeout)
    }
    setIsCountdownDone(false)
    dispatch(clearSessionReduxData(new Date().getTime()))
    setAudios([])
    setErrorWhileAudiosCatch(false)
    setDeviceTestSteps(deviceTestSteps.prevStep)
  }

  let timeout: null | NodeJS.Timeout = null

  useEffect(() => {
    if (is_eula_accepted && eula_url !== '' && isFirstLogin) {
      setDeviceTestSteps(steps.DEVICE_CONTROL)
    } else {
      setDeviceTestSteps(steps.START)
    }
    setIsCountdownDone(false)
    setCounter(0)
    return () => {
      if (conversionTimeout) {
        clearTimeout(conversionTimeout)
      }
      setDeviceTestSteps(steps.START)
      setCounter(0)
      setIsCountdownDone(false)
      dispatch(clearSessionReduxData(new Date().getTime()))
      dispatch(updateGetterForSession({ isSubscription: false }))
    }
  }, [])

  const downloadUrlConversions = session.conversions?.filter(
    ({ download_url }) => !!download_url,
  )
  const isEmptySession = !session.download_url

  useEffect(() => {
    if (deviceTestSteps.step === 3 && sessionTestId && !session.download_url) {
      downloadTimeout = setTimeout(() => {
        dispatch(
          getSession({
            id: sessionTestId,
            silent: true,
            getTranscription: false,
          }),
        )
      }, 1000)
    }
    if (downloadTimeout && session.download_url) {
      clearTimeout(downloadTimeout)
    }
  }, [deviceTestSteps, session])

  const [errorWhileAudiosCatch, setErrorWhileAudiosCatch] = useState(false)
  const getAudiosElements = async () => {
    let conversions = []
    for (const conversion of (session as any).conversions) {
      try {
        const url = await getAudio((conversion as any).download_url)
        conversions.push({
          url,
          isConversion: true,
          name: (conversion as any).name,
          description: (conversion as any).description,
          id: (conversion as any).id,
        })
      } catch {
        setErrorWhileAudiosCatch(true)
      }
    }
    try {
      const url = await getAudio((session as any).download_url)
      setAudios([
        ...conversions,
        {
          isConversion: false,
          url,
          id: (session as any).id,
          description: (session as any).description,
          name: session.user?.username,
        },
      ])
    } catch {
      setErrorWhileAudiosCatch(true)
    }
  }
  const [conversionGetAttempt, setConversionGetAttempt] = useState(0)

  const conversionGetter = () => {
    conversionTimeout = setTimeout(() => {
      setConversionGetAttempt(conversionGetAttempt + 1)
      clearTimeout(conversionTimeout)
    }, 2000)
  }

  const [showError, setShowError] = useState(false)

  const onChangeError = useAsyncDebounce((isError) => {
    setShowError(isError)
  }, 100)

  useEffect(() => {
    if (
      areAudioPermissions &&
      availableDevices.output_devices &&
      availableDevices.output_devices?.length > 0 &&
      selectedDevices.output_device?.device_name !== ''
    ) {
      onChangeError(true)
    } else {
      onChangeError(false)
    }
  }, [
    areAudioPermissions &&
      availableDevices.output_devices &&
      availableDevices.output_devices?.length > 0 &&
      selectedDevices.output_device?.device_name !== '',
  ])

  useEffect(() => {
    if (!session.download_url || isEmptySession) {
      return
    }
    ;(async () => {
      if (
        !isLoadingSession &&
        Object.keys(session).length > 0 &&
        downloadUrlConversions &&
        downloadUrlConversions.length > 0
      ) {
        const url = await getAudio(downloadUrlConversions[0].download_url)
        if (url) {
          await getAudiosElements()
          dispatch(updateGetterForSession({ isSubscription: false }))
        } else {
          conversionGetter()
        }
      }
    })()
  }, [
    conversionGetAttempt,
    isLoadingSession,
    session.download_url,
    downloadUrlConversions?.length,
    session.conversions?.length,
  ])

  useEffect(() => {
    return () => {
      if (timeout) {
        clearTimeout(timeout)
        timeout = null
      }
      if (downloadTimeout) {
        clearTimeout(downloadTimeout)
        downloadTimeout = null
      }
    }
  }, [])
  const sessionData =
    Object.keys(session).length > 0
      ? [
          ...(session as ISessionDetails).conversions?.map(
            ({ id, download_url, description, name, transcription }: any) => ({
              isConversion: true,
              url: download_url,
              id,
              description: description,
              name: name,
              transcription,
            }),
          ),
          {
            isConversion: false,
            url: (session as any).download_url,
            id: (session as any).id,
            description: (session as any).description,
            transcription: (session as any).transcription,
            name: (session as any).user?.username,
          },
        ]
      : []

  const stepOptions = {
    [STEP_OPTIONS.START]: () => (
      <Flex flexDirection="column" alignItems="center" gap="5">
        <Flex textAlign="center">
          {isFirstLogin ? (
            <Flex flexDirection="column" alignItems="center" gap="5">
              <Text fontSize="18px" fontWeight="semibold">
                Let's get started!
              </Text>
              <Text fontSize="xl">
                The following steps will verify your account is configured
                correctly and you're ready to go
              </Text>
            </Flex>
          ) : (
            'The following steps will be verify your account is configured correctly'
          )}
        </Flex>
      </Flex>
    ),
    [STEP_OPTIONS.DEVICE_CONTROL]: () => (
      <Flex flexDirection="column" alignItems="center" gap="5">
        <Text textAlign="center">
          Make sure your input device is configured to your microphone (same as
          system) and output device to the relevant virtual microphone.
        </Text>
        {(!isVirtualMicrophoneInstalled || !testedVirtualMicrophone) &&
          showError && (
            <>
              {!isVirtualMicrophoneInstalled ? (
                <Alert
                  status="error"
                  borderRadius="md"
                  data-qa__id="vb_driver_error"
                >
                  <AlertIcon />
                  <Text>
                    You must install a virtual microphone. We recommend using{' '}
                    <Link
                      href="https://vb-audio.com/Cable/"
                      color="brand.500"
                      _hover={{ textDecoration: 'underline' }}
                      variant="link"
                    >
                      VB Cable
                    </Link>
                    .
                  </Text>
                </Alert>
              ) : !testedVirtualMicrophone ? (
                <Alert
                  status="warning"
                  borderRadius="md"
                  data-qa__id="vb_driver_warning"
                >
                  <AlertIcon />
                  <Text>
                    You can use any virtual microphone but we recommend using{' '}
                    <Link
                      href="https://vb-audio.com/Cable/"
                      color="brand.500"
                      _hover={{ textDecoration: 'underline' }}
                      variant="link"
                    >
                      VB Cable
                    </Link>
                    .
                  </Text>
                </Alert>
              ) : (
                <></>
              )}
            </>
          )}
        <DevicesController isModalView={true} isOpenDeviceController={true} />
      </Flex>
    ),
    [STEP_OPTIONS.TEST_CALL]: () => (
      <Flex flexDirection="column" w="100%" alignItems="center" gap="5">
        <Text textAlign="center">
          Select a voice avatar and record your voice for 10 seconds
        </Text>
        {carousel}
      </Flex>
    ),
    [STEP_OPTIONS.CHECK_TEST]: () => (
      <Flex flexDirection="column" w="100%" alignItems="center" gap="5">
        <Text textAlign="center">
          Listen to the recording of the enhanced voice to verify it sounds as
          expected
        </Text>
        {!errorWhileAudiosCatch && (isLoadingSession || audios.length === 0) ? (
          <Flex
            w="full"
            h="100%"
            justifyContent="center"
            alignItems="center"
            alignSelf="center"
          >
            <Spinner size="xl" />
          </Flex>
        ) : !session.download_url ? (
          <Flex
            w="full"
            h="100%"
            justifyContent="center"
            alignItems="center"
            alignSelf="center"
          >
            No audio
          </Flex>
        ) : (
          <>
            <SessionComponent
              audios={audios}
              embedded={true}
              disabled={false}
              data={data}
              isLoadingAudios={false}
              isDemo={true}
            />
          </>
        )}
      </Flex>
    ),
    [STEP_OPTIONS.SURVEY]: () => (
      <Flex flexDirection="column" w="100%" alignItems="center" gap="5">
        <Survey
          isFirstLogin={isFirstLogin}
          onSubmit={() => onSubmit(true)}
          setSelectedIssue={setSelectedIssue}
          selectedIssue={selectedIssue}
          issueDescription={issueDescription}
          setIssueDescription={setIssueDescription}
          rating={rating}
          setRating={setRating}
          additionalButton={
            <Button
              w="80px"
              alignSelf="flex-end"
              onClick={onPrevStep}
              variant="outline"
              colorScheme="brand"
              color="brand.500"
            >
              Back
            </Button>
          }
        />
      </Flex>
    ),
  }

  const headerSteps = Object.keys(steps)
    .filter((key) => !(steps[key].step === 0 && isEulaFlow))
    .map((key) => ({
      title: steps[key].description,
      description: steps[key].description,
    }))

  const { activeStep, setActiveStep } = useSteps({
    index: 1,
    count:
      potential_issues.length === 0 || !can_show_quality_survey
        ? Object.keys(steps).length - (isEulaFlow ? 2 : 1)
        : Object.keys(steps).length - (isEulaFlow ? 1 : 0),
  })

  const stepType = useMemo(() => {
    setActiveStep(deviceTestSteps.step - (isEulaFlow ? 1 : 0))
    if (deviceTestSteps.step === 0) {
      return STEP_OPTIONS.START
    }
    if (deviceTestSteps.step === 1) {
      return STEP_OPTIONS.DEVICE_CONTROL
    }
    if (deviceTestSteps.step === 2) {
      return STEP_OPTIONS.TEST_CALL
    }
    if (deviceTestSteps.step === 3) {
      return STEP_OPTIONS.CHECK_TEST
    }
    if (deviceTestSteps.step === 4) {
      return STEP_OPTIONS.SURVEY
    }
    setActiveStep(1)
    return STEP_OPTIONS.DEVICE_CONTROL
  }, [deviceTestSteps, testedVirtualMicrophone])

  const [isMobile] = useMediaQuery(mobileBreakPoint)

  return (
    <Modal
      size={isMobile ? 'md' : '2xl'}
      closeOnOverlayClick={false}
      isOpen={deviceTest.isOpen}
      onClose={closeModal}
    >
      <ModalOverlay />
      <ModalContent alignSelf="center">
        <ModalCloseButton />
        <ModalHeader
          alignItems="center"
          justifyContent="center"
          display="flex"
          flexDirection="column"
          textAlign="center"
        >
          <Box p="35px 0 15px 0" width="100%" borderTopRadius="md">
            <Stepper size="sm" colorScheme="brand" index={activeStep}>
              {headerSteps
                .filter((step) =>
                  step.description === 'Done'
                    ? potential_issues.length !== 0 && can_show_quality_survey
                    : true,
                )
                .map((step, index) => (
                  <Step key={`stepper_${step.title}_${index}`}>
                    <StepIndicator
                      color={activeStep === index ? 'brand.500' : 'black'}
                    >
                      <StepStatus
                        complete={<StepIcon />}
                        incomplete={<StepNumber />}
                        active={<StepNumber />}
                      />
                    </StepIndicator>

                    <Box
                      color={activeStep === index ? 'brand.500' : 'black'}
                      flexShrink="2"
                    >
                      {!isMobile ? (
                        <Text fontSize="xs">{step.title}</Text>
                      ) : null}
                    </Box>

                    <StepSeparator />
                  </Step>
                ))}
            </Stepper>
          </Box>
          {isFirstLogin && deviceTestSteps.step === 0 && (
            <Image src="/logo.svg" w="2.5em" />
          )}
          <Text data-qa__id="wizard_stepper_title">
            {deviceTestSteps.header}{' '}
          </Text>
        </ModalHeader>
        <ModalBody pb={6}>
          <Box>
            {stepOptions[stepType]()}
            {deviceTestSteps.step === 2 && (
              <Flex
                alignItems="flex-end"
                w="100%"
                fontSize="md"
                h="50px"
                justifyContent="space-around"
              >
                <Flex w="100px" />
                <Flex
                  w="300px"
                  fontSize="md"
                  flexDirection="column"
                  alignItems="center"
                  gap="1"
                  color={mediaState.isConnected ? 'red' : 'black'}
                >
                  {mediaState.isConnected ? (
                    <Text fontSize="md">
                      Recording: {10 - counter > 0 ? 10 - counter : 0}
                    </Text>
                  ) : isCountdownDone ? (
                    <Text fontSize="md" textAlign="center">
                      Record again, by clicking on an avatar or click next to
                      continue.
                    </Text>
                  ) : (
                    <Text h="24px" />
                  )}
                </Flex>
                <Flex w="100px" />
                {mediaState.isConnected && (
                  <Flex position="absolute" bottom="25px" left="25px">
                    <Text>Latency:</Text>
                    <Text w="50px" textAlign="end" data-qa__id="wizard_latency">
                      {Math.floor(Number(mediaState.latency))}
                    </Text>
                  </Flex>
                )}
              </Flex>
            )}
          </Box>
        </ModalBody>
        {deviceTestSteps.step !== 4 &&
          (deviceTestSteps.prevStep || deviceTestSteps.nextStep) && (
            <ModalFooter gap="3">
              {deviceTestSteps.prevStep && (
                <Button
                  w="80px"
                  alignSelf="flex-end"
                  onClick={onPrevStep}
                  isDisabled={mediaState.isConnected}
                  variant="outline"
                  colorScheme="brand"
                  color="brand.500"
                >
                  Back
                </Button>
              )}
              {deviceTestSteps.nextStep && (
                <Button
                  data-qa__id="wizard_next_button"
                  isDisabled={
                    (deviceTestSteps.step === 1 &&
                      !selectedDevices?.output_device?.device_id) ||
                    (deviceTestSteps.step === 2 &&
                      (mediaState.isConnected || !isCountdownDone))
                  }
                  w="80px"
                  alignSelf="flex-end"
                  colorScheme="brand"
                  onClick={onNextStep}
                >
                  {deviceTestSteps.step === 3 &&
                  (potential_issues.length === 0 || !can_show_quality_survey)
                    ? 'Submit'
                    : 'Next'}
                </Button>
              )}
            </ModalFooter>
          )}
      </ModalContent>
    </Modal>
  )
}
