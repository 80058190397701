import 'flag-icons/css/flag-icons.min.css'

import React, { lazy, Suspense, useEffect } from 'react'

import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import PrivateRoute, { loader } from './PrivateRoute'
import { useAppDispatch, useAppSelector } from './utils/reduxUtils'
import { checkIfLogged, setTray } from './features/authorization/actions'
import {
  Box,
  Flex,
  Spinner,
  useColorModeValue,
  useMediaQuery,
} from '@chakra-ui/react'
import { PageNotFound } from './pages/PageNotFound/notFound'
import Live from './pages/Live/live'
import LiveIframe from './pages/LiveIframe/liveIframe'
import { mobileBreakPoint } from './utils/mobileBreakPoint'

const TermsAndConditions = lazy(
  () => import('./pages/TermsAndConditions/terms'),
)
const LoginPage = lazy(() => import('./pages/LoginPage/login'))
const DemoPage = lazy(() => import('./pages/DemoPage/demo'))
const DemoInFramePage = lazy(() => import('./pages/DemoPage/demoInFrame'))
const LiveIframeLogin = lazy(
  () => import('./pages/LiveIframeLogin/liveIframeLogin'),
)

const CCWPage = lazy(() => import('./pages/CCWPage/demo'))
const CCWInFramePage = lazy(() => import('./pages/CCWPage/demoInFrame'))

const Sessions = lazy(() => import('./pages/Sessions/sessions'))
const Maintenance = lazy(() => import('./pages/Maintenance/maintenance'))
const UploadRecordings = lazy(
  () => import('./pages/UploadRecordings/uploadRecordings'),
)
const SessionView = lazy(() => import('./pages/Session/session'))
import { Test } from './pages/PingTest/test'

export const publicPages = [
  {
    path: '/test',
    element: <Test />,
  },
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/demo',
    element: <DemoPage />,
  },
  {
    path: '/demo-in-frame',
    element: <DemoInFramePage />,
  },
  {
    path: '/ccw',
    element: <CCWPage />,
  },
  {
    path: '/ccw-in-frame',
    element: <CCWInFramePage />,
  },
  {
    path: '/maintenance',
    element: <Maintenance />,
  },
  {
    path: '/terms',
    element: <TermsAndConditions />,
  },
].map((item, index) => ({ ...item, id: `path_${index + 1}_${item.path}` }))

export const privatePagesDesktop = [
  {
    path: '/',
    element: <Sessions />,
  },
  {
    path: '/live',
    element: <Live />,
  },
  {
    path: '/upload-sessions',
    element: <UploadRecordings />,
  },
  {
    path: '/studio',
    element: <Sessions />,
  },
  {
    path: '/dashboard',
    element: <Sessions />,
  },
  {
    path: '/session',
    element: <Navigate replace to="/new_session" />,
  },
  {
    path: '/session/:id',
    element: <SessionView />,
  },
].map((item, index) => ({ ...item, id: `path_${index + 1}_${item.path}` }))

const Wrapper = () => {
  const { loading, isLogged } = useAppSelector((state) => state.authorization)
  const dispatch = useAppDispatch()
  const { is_supervisor } = useAppSelector((state) => state.user)

  const isIframe = window.location.pathname.includes('live-iframe')

  useEffect(() => {
    if (!isIframe) {
      dispatch(checkIfLogged())
    }
    return () => {
      if (!isIframe) {
        localStorage.removeItem('next')
      }
    }
  }, [])

  const { features, is_eula_accepted, eula_url } = useAppSelector(
    (state) => state.user,
  )

  const canEditSession =
    features.filter((el) => el === 'can_edit_session').length > 0
  const [isMobile] = useMediaQuery(mobileBreakPoint)

  const bg = useColorModeValue('white', 'gray.800')

  const privatePages = privatePagesDesktop.filter(({ path }) => {
    if (!canEditSession && path === '/new_session') {
      return false
    }
    if (!is_supervisor && path === '/upload-recordings') {
      return false
    }
    return true
  })
  return (
    <Box
      height="100%"
      bg={bg}
      pb="auto"
      display={loading ? 'flex' : 'block'}
      alignItems="center"
    >
      <BrowserRouter>
        <Suspense
          fallback={
            <Flex
              justifyContent="center"
              w="100%"
              h="100vh"
              alignItems="center"
            >
              <Spinner size="xl" />
            </Flex>
          }
        >
          <Routes>
            {isIframe ? (
              <>
                <Route
                  path={'/live-iframe'}
                  element={<LiveIframeLogin />}
                  key="iframe_login"
                />
                <Route
                  path={'/live-iframe/:token'}
                  element={<LiveIframe />}
                  key="live_iframe"
                />
              </>
            ) : (
              <>
                {publicPages.map(({ path, element, id }) => (
                  <Route
                    path={path}
                    element={
                      loading && window?.location.pathname !== '/login'
                        ? loader
                        : element
                    }
                    key={id}
                  />
                ))}
                {privatePages.map(({ path, element, id }) =>
                  PrivateRoute({
                    path,
                    key: `private_route_${path}_${id}`,
                    isLogged,
                    isMobile,
                    loading,
                    element,
                    is_eula_accepted: eula_url !== '' ? is_eula_accepted : true,
                  }),
                )}
              </>
            )}

            {!loading && isLogged && (
              <Route path="*" element={<PageNotFound />} />
            )}
          </Routes>
        </Suspense>
      </BrowserRouter>
    </Box>
  )
}

export default Wrapper
