import React, { ReactNode, useEffect, useState } from 'react'
import {
  AppDispatch,
  useAppDispatch,
  useAppSelector,
} from '../../utils/reduxUtils'
import analyserFrequency from 'analyser-frequency-average'
import { IAudioDevice } from '../../components/DevicesController/devicesController'
import {
  infer,
  openPingServiceTransport,
  closePingServiceTransport,
  openInferenceServiceTransport,
  closeInferenceServiceTransport,
} from '../../utils/audioUtils'
import {
  setDeviceTest,
  setSelectedVoice,
  setSelectedDevices,
  updateDeviceTest,
  updateMediaState,
} from '../../features/audio/actions'
import { CreateToastFnReturn, Flex, useToast } from '@chakra-ui/react'
import { v4 as uuidv4 } from 'uuid'
import { OpusDecoderWebWorker } from 'opus-decoder'
import {
  setLiveManagementSession,
  setLiveSession,
  updateGetter,
} from '../../features/audioManagement/actions'
import { logout } from '../../features/authorization/actions'
import { newVoiceElements } from './live'
import { PreventChangePath } from '../../components/PreventChangePath/preventChangePath'
import { useCallbackPrompt } from '../../customHooks/useCallbackPrompt'
import { ModalSurvey } from '../../components/Survey/modalSurvey'
import { DeviceTestModal } from '../../components/DeviceTestModal/deviceTestModal'
import { setAsyncDelayFilter } from '../../utils/setAsyncDelayFilter'
import { IUser } from '../../models/user/user'
import { IMediaState } from '../../models/audio/audio'
import regions from '../Sessions/regions.json'

export enum EConversionState {
  draft = 'draft',
  processing = 'processing',
  completed = 'completed',
}

let messageBus = {
  send: (message: string, value: string) => {
    return [message, value]
  },
}

let pingDispatcherTimeout = 0
let audioCheckDispatcherTimeout = 0
let audioDispatcherTimeout = 0
let isInputAudioOk = false
let isOutputAudioOk = false

try {
  messageBus = window.require('electron').ipcRenderer
} catch (_) {}

export enum EConversionType {
  manual = 'manual',
  generated = 'generated',
}
export enum ERecordingState {
  draft = 'draft',
  processing = 'processing',
  completed = 'completed',
}

const decoder = new OpusDecoderWebWorker({
  sampleRate: 16000,
} as any) // Upstream added sampleRate, but didn't update d.ts

export enum ESystemMessage {
  CONNECTED = 'CONNECTED',
  SESSION_ID = 'SESSION_ID',
  DISCONNECTED = 'DISCONNECTED',
  INPUT_DISCONNECTED = 'INPUT_DISCONNECTED',
  INPUT_CONNECTED = 'INPUT_CONNECTED',
  INPUT_DEVICE_SELECTED = 'INPUT_DEVICE_SELECTED',
  OUTPUT_DISCONNECTED = 'OUTPUT_DISCONNECTED',
  OUTPUT_CONNECTED = 'OUTPUT_CONNECTED',
  OUTPUT_DEVICE_SELECTED = 'OUTPUT_DEVICE_SELECTED',
  LATENCY = 'LATENCY',
  INPUT_AUDIO_REGISTERED = 'INPUT_AUDIO_REGISTERED',
  OUTPUT_AUDIO_REGISTERED = 'OUTPUT_AUDIO_REGISTERED',
  AVAILABLE_INPUT_DEVICES = 'AVAILABLE_INPUT_DEVICES',
  AVAILABLE_OUTPUT_DEVICES = 'AVAILABLE_OUTPUT_DEVICES',
  AVAILABLE_AVATARS = 'AVAILABLE_AVATARS',
}

export const broadcastSystemEvent = (
  event: ESystemMessage,
  value?: unknown,
) => {
  try {
    console.log(event, value)
    if (value !== undefined) {
      window.top?.postMessage({ event: event, value: value }, '*')
    } else {
      window.top?.postMessage(event, '*')
    }

    if (
      event === ESystemMessage.CONNECTED ||
      event === ESystemMessage.DISCONNECTED
    ) {
      messageBus.send('liveConnectionState', event)
    }
  } catch {}
}

// TODO: Also show input and output controls in the UI (highlight once for each device change)
// TODO: send input_audio_ok, output_audio_ok and latency through postMessage
// TODO: Send ready event on media pipe dispatch

const buildStream = async (
  metadata: any,
  is_controlled: boolean,
  mediaState: IMediaState,
  custom_inference_url: string,
  toast: CreateToastFnReturn,
  dispatch: AppDispatch,
  inputDevice: IAudioDevice,
  outputDevice: IAudioDevice | null,
  inputAudioContext: AudioContext,
  sessionId: string,
  dataCenterId: string,
  user_data: IUser,
  modelLatency?: number,
  voiceId?: string,
  modelId?: string,
  voiceDisplayName?: string,
  onOpenSurvey?: (options: any) => void,
  deviceTest?: { isOpen: boolean },
  obs_sync?: boolean,
  onEnd?: () => void,
) => {
  window.clearTimeout(pingDispatcherTimeout)
  window.clearTimeout(audioCheckDispatcherTimeout)
  window.clearTimeout(audioDispatcherTimeout)
  isInputAudioOk = false
  isOutputAudioOk = false

  let inputStreamNode: AudioWorkletNode

  await decoder.reset()
  await decoder.ready

  let transportReader: any = null
  let pingTransportReader: any = null
  let pingTransportWriter: any = null

  // TODO: Try https://developer.mozilla.org/en-US/docs/Web/API/BaseAudioContext/createBufferSource
  try {
    // Useful resources
    // https://developer.mozilla.org/en-US/docs/Web/API/AudioWorkletNode
    // https://developer.chrome.com/blog/audio-worklet/
    // https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Using_AudioWorklet
    await inputAudioContext.audioWorklet.addModule(
      '../worklet/processor_input.js',
    )
  } catch (e) {
    onEnd?.()
    console.warn(e)
  }

  try {
    inputStreamNode = new AudioWorkletNode(inputAudioContext, 'processor_input')
  } catch {
    onEnd?.()
    dispatch(updateMediaState({ isConnecting: false }))
    return
  }

  inputStreamNode.port.onmessage = (event) => {
    if (event.data && voiceId && modelId) {
      infer(event.data, sessionId, voiceId, modelId, 16_000)
    }
  }

  if (voiceId && modelId) {
    const startTime = new Date().getTime()
    try {
      const { pingReader, pingWriter } = await openPingServiceTransport(
        sessionId,
        custom_inference_url,
        dataCenterId,
        () => {
          pingTransportReader = null
          pingTransportWriter = null
          closePingServiceTransport()
        },
        () => {
          pingTransportReader = null
          pingTransportWriter = null
          closePingServiceTransport()
        },
      )
      pingTransportReader = pingReader
      pingTransportWriter = pingWriter
    } catch {}

    const { reader } = await openInferenceServiceTransport(
      deviceTest?.isOpen,
      metadata,
      mediaState,
      custom_inference_url,
      dataCenterId,
      sessionId,
      voiceId,
      modelId,
      user_data,
      (session_id) => {
        dispatch(
          updateMediaState({
            latency: 0,
            datacenter: '',
            isConnected: true,
            isConnecting: false,
          }),
        )
      },
      () => {
        if (obs_sync) {
          setAsyncDelayFilter(0)
        }
        onEnd?.()
        // check if current time of recording is longer than 10 second and open survey
        if (onOpenSurvey && inputAudioContext?.currentTime > 10) {
          onOpenSurvey({ isOpen: true, sessionId })
        }

        transportReader = null

        dispatch(
          setSelectedVoice({
            voice: newVoiceElements(),
          }),
        )

        dispatch(
          updateMediaState({
            latency: 0,
            datacenter: '',
            isConnected: false,
            isConnecting: false,
            isRecording: false,
          }),
        )
      },
      (delayError: boolean) => {
        if (obs_sync) {
          setAsyncDelayFilter(0)
        }

        if (!transportReader) {
          if (onOpenSurvey) {
            onOpenSurvey({ isOpen: true, sessionId })
          }

          const closeTime = new Date().getTime()

          if (closeTime - startTime <= 5000 || delayError) {
            toast({
              title: `Failed to connect to inference server. Please make sure that your firewall is not blocking port 443`,
              position: 'bottom-left',
              status: 'warning',
              isClosable: true,
            })
          }
          if (is_controlled) {
            dispatch(
              setLiveManagementSession({
                session_id: sessionId,
                avatar_display_name: '',
                avatar_id: '',
                is_started: false,
                is_recording: false,
                available_devices: [],
              }),
            )
          }
        }

        closeInferenceServiceTransport()

        if (inputAudioContext.state !== 'closed') {
          inputAudioContext?.close()
        }

        inputStreamNode?.disconnect()

        dispatch(
          setSelectedVoice({
            voice: newVoiceElements(),
          }),
        )

        onEnd?.()
        dispatch(
          updateMediaState({
            datacenter: '',
            latency: 0,
            isConnected: false,
            isConnecting: false,
            isRecording: false,
          }),
        )

        transportReader = null
      },
      voiceDisplayName,
    )

    transportReader = reader
  }

  const analyser = new AnalyserNode(inputAudioContext, {
    fftSize: 64,
    maxDecibels: -10,
    minDecibels: -80,
    smoothingTimeConstant: 0.8,
  })
  const outputAnalyser = new AnalyserNode(inputAudioContext, {
    fftSize: 64,
    maxDecibels: -10,
    minDecibels: -60,
    smoothingTimeConstant: 0.75,
  })

  const audioDispatcher = async () => {
    isInputAudioOk = false
    isOutputAudioOk = false
    dispatch(updateMediaState({ isInputAudioRegistered: false }))
    dispatch(updateMediaState({ isOutputAudioRegistered: false }))

    let inputStream = await navigator.mediaDevices
      .getUserMedia({
        audio: {
          deviceId: {
            exact: inputDevice.device_id || inputDevice.api_id,
          },
          sampleRate: {
            min: 16000,
          },
          //@ts-ignore
          latency: 0,
          channelCount: 1,
          autoGainControl: false,
          echoCancellation: false,
          noiseSuppression: false,
        },
      })
      .catch((e) => {
        dispatch(updateMediaState({ isInputConnected: false }))
        broadcastSystemEvent(ESystemMessage.INPUT_DISCONNECTED)
        console.warn(e)

        audioDispatcherTimeout = window.setTimeout(() => {
          audioDispatcher()
        }, 1000)
      })

    if (!inputStream) {
      inputStream = inputAudioContext.createMediaStreamDestination().stream
    } else {
      ;((inputStream: MediaStream, inputAudioContext: AudioContext) => {
        inputAudioContext.onstatechange = (_) => {
          if (inputAudioContext.state === 'closed') {
            inputStream?.getTracks().forEach((track: any) => {
              track.stop()
            })
          }
        }
      })(inputStream, inputAudioContext)
      dispatch(updateMediaState({ isInputConnected: true }))
      broadcastSystemEvent(ESystemMessage.INPUT_CONNECTED)
    }

    const source = inputAudioContext.createMediaStreamSource(inputStream)
    const audioTrack = inputStream.getAudioTracks()[0]

    audioTrack.addEventListener('ended', () => {
      dispatch(updateMediaState({ isInputConnected: false }))
      broadcastSystemEvent(ESystemMessage.INPUT_DISCONNECTED)
      audioDispatcherTimeout = window.setTimeout(() => {
        audioDispatcher()
      }, 1000)
    })

    const destination = inputAudioContext.createMediaStreamDestination()

    if (voiceId && modelId) {
      source.connect(analyser).connect(inputStreamNode)
      inputStreamNode.connect(inputAudioContext.destination)
      inputStreamNode.connect(outputAnalyser).connect(destination)
      if (obs_sync) {
        setAsyncDelayFilter(modelLatency)
      }
    } else {
      dispatch(updateMediaState({ isConnecting: false }))
      source.connect(analyser).connect(inputAudioContext.destination)
    }

    if ((inputAudioContext as any)?.state !== 'closed') {
      if (outputDevice && outputDevice.device_id !== null) {
        await (inputAudioContext as any).setSinkId(
          outputDevice.device_id === 'default' ? '' : outputDevice.device_id,
        )
        dispatch(updateMediaState({ isOutputConnected: true }))
        broadcastSystemEvent(ESystemMessage.OUTPUT_CONNECTED)
      } else {
        await (inputAudioContext as any).setSinkId({ type: 'none' })
        dispatch(updateMediaState({ isOutputConnected: false }))
        broadcastSystemEvent(ESystemMessage.OUTPUT_DISCONNECTED)
      }
    }
  }

  let pingLatency = 0

  const pingDispatcher = async () => {
    window.clearTimeout(pingDispatcherTimeout)
    pingDispatcherTimeout = window.setTimeout(pingDispatcher, 1000)
    if (pingTransportReader && pingTransportWriter) {
      const buffer = new ArrayBuffer(16)
      const view = new DataView(buffer)

      view.setBigUint64(0, BigInt(new Date().getTime()), false)
      view.setBigUint64(8, BigInt(pingLatency), false)

      const uint8Array = new Uint8Array(buffer)
      pingTransportWriter.write(uint8Array)

      const { value, done } = await pingTransportReader.read().catch(() => {
        return { value: null, done: true }
      })

      if (!value || done) {
        pingLatency = 0
        return
      }

      const dataView = new DataView(new Uint8Array(value).buffer)
      const latency = dataView.getUint8(0)
      const receivedTimestamp = Number(dataView.getBigUint64(8, true))
      const datacenterID = Number(dataView.getBigUint64(16, true))

      pingLatency = Math.abs(receivedTimestamp - new Date().getTime())

      const regionKeys = Object.keys(regions) as string[]
      const regionKey = regionKeys[datacenterID - 1] as keyof typeof regions

      let datacenter = ''
      if (regionKey && regions[regionKey]) {
        datacenter = regions[regionKey].name
      } else {
        console.log('Datacenter ID does not correspond to any region.')
      }

      dispatch(updateMediaState({ latency: pingLatency, datacenter }))
      broadcastSystemEvent(ESystemMessage.LATENCY, pingLatency)
    }
  }

  const dispatcher = async () => {
    if (transportReader) {
      try {
        broadcastSystemEvent(ESystemMessage.CONNECTED)
        broadcastSystemEvent(ESystemMessage.SESSION_ID, sessionId)

        while (true) {
          const { value, done } = await transportReader.read().catch(() => {
            return { value: null, done: true }
          })

          if (!value || done) {
            break
          }

          const splitValue = [...value]
            .join(',')
            .split([1, 2, 3, 255, 0, 255, 3, 2, 1].join(','))
            .map(
              (item) =>
                new Uint8Array(
                  item
                    .split(',')
                    .filter((sub) => sub != '')
                    .map((sub) => Number(sub)),
                ),
            )
            .filter((item) => item.length > 0)

          for (const frame of splitValue) {
            const { channelData, errors } = await decoder.decodeFrame(frame)

            if (!errors.length) {
              inputStreamNode.port.postMessage(channelData[0])
            } else {
              console.warn(errors)
            }
          }
        }
      } catch (error) {
        closePingServiceTransport()
        broadcastSystemEvent(ESystemMessage.DISCONNECTED)
      } finally {
        closePingServiceTransport()
        broadcastSystemEvent(ESystemMessage.DISCONNECTED)
      }
    }
  }

  const audioCheckDispatcher = () => {
    if (!isInputAudioOk) {
      const inputFrequencies = new Uint8Array(analyser.frequencyBinCount)
      analyser.getByteFrequencyData(inputFrequencies)
      const inputAverage = analyserFrequency(
        analyser,
        inputFrequencies,
        40,
        16000,
      )

      if (inputAverage > 0) {
        isInputAudioOk = true
        dispatch(updateMediaState({ isInputAudioRegistered: true }))
        broadcastSystemEvent(ESystemMessage.INPUT_AUDIO_REGISTERED)
      }
    }

    if (!isOutputAudioOk) {
      const outputFrequencies = new Uint8Array(outputAnalyser.frequencyBinCount)
      outputAnalyser.getByteFrequencyData(outputFrequencies)
      const outputAverage = analyserFrequency(
        outputAnalyser,
        outputFrequencies,
        40,
        16000,
      )

      if (outputAverage > 0) {
        isOutputAudioOk = true
        dispatch(updateMediaState({ isOutputAudioRegistered: true }))
        broadcastSystemEvent(ESystemMessage.OUTPUT_AUDIO_REGISTERED)
      }
    }

    audioCheckDispatcherTimeout = window.setTimeout(audioCheckDispatcher, 1000)
  }

  dispatcher()
  audioDispatcher()
  audioCheckDispatcher()

  try {
    pingDispatcher()
  } catch {}
}

const LiveSession = ({
  children,
  carousel,
  canShowDeviceTest,
}: {
  children: ReactNode
  carousel: ReactNode
  canShowDeviceTest: boolean
}) => {
  const {
    deviceTest,
    selectedDevices,
    selectedVoice,
    areAudioPermissions,
    mediaState,
    availableDevices,
  } = useAppSelector((state) => state.audio)
  const {
    data_center,
    custom_inference_url,
    features,
    should_show_device_test,
    is_first_login,
    meaning_user_id,
    s3_models,
  } = useAppSelector((state) => state.user)

  const obs_sync = !!features.find((feature) => feature === 'obs_sync')
  const {
    pauseGetter,
    liveSession: liveSessionData,
    metadata,
  } = useAppSelector((state) => state.audioManagement)

  const dispatch = useAppDispatch()
  const [sessionId, setSessionId] = useState('')
  const [media, setMedia] = useState<any>(null)
  const toast = useToast()

  const [isOpenSurvey, setIsOpenSurvey] = useState<{
    isOpen: boolean
    sessionId: string | null
  }>({ isOpen: false, sessionId: null })

  const onCloseSurvey = () => {
    setIsOpenSurvey({ isOpen: false, sessionId: null })
  }
  const onOpenSurvey = (sessionId: {
    isOpen: boolean
    sessionId: string | null
  }) => {
    setIsOpenSurvey(sessionId)
  }

  const setEmptyLiveSession = () => {
    if (is_controlled) {
      dispatch(
        setLiveManagementSession({
          session_id: '',
          avatar_display_name: '',
          avatar_id: '',
          is_recording: false,
          is_started: false,
          available_devices: {},
        }),
      )
    }
  }

  useEffect(() => {
    const handleMessage = (
      event: MessageEvent<{
        action: string
        avatar_id?: string
        record?: boolean
        input_device?: IAudioDevice
        output_device?: IAudioDevice
      }>,
    ) => {
      if (event.data.action === 'start_session' && event.data.avatar_id != '') {
        const voices = s3_models.map((model) => model.voices).flat()
        const selectedVoice = voices.find(
          ({ avatar_identifier }) => avatar_identifier === event.data.avatar_id,
        )

        if (selectedVoice) {
          dispatch(
            updateMediaState({
              canBeRecorded: true,
              shouldBeRecorded: event.data.record,
            }),
          )
          dispatch(
            setSelectedVoice({
              voice: selectedVoice,
            }),
          )
        }

        return
      }

      if (event.data.action === 'stop_session' && event.data.avatar_id != '') {
        dispatch(
          setSelectedVoice({
            voice: newVoiceElements(),
          }),
        )

        return
      }

      if (event.data.action === 'logout') {
        dispatch(logout({ url: logout_url }))

        return
      }

      if (
        event.data.action === 'set_input_device' &&
        event.data.input_device != undefined
      ) {
        dispatch(
          setSelectedDevices({
            ...selectedDevices,
            input_device: event.data.input_device,
          }),
        )

        return
      }

      if (
        event.data.action === 'set_output_device' &&
        event.data.output_device != undefined
      ) {
        dispatch(
          setSelectedDevices({
            ...selectedDevices,
            output_device: event.data.output_device,
          }),
        )

        return
      }
    }

    window.addEventListener('message', handleMessage, false)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  })

  useEffect(() => {
    if (
      areAudioPermissions &&
      availableDevices.input_devices !== null &&
      !pauseGetter
    ) {
      if (!mediaState.isConnected && !mediaState.isConnecting) {
        dispatch(updateGetter(true))
      }
    }
  }, [areAudioPermissions, availableDevices])

  useEffect(() => {
    return () => {
      setEmptyLiveSession()
      dispatch(updateGetter(false))
    }
  }, [])

  const [isConnected, setIsConnected] = useState<any>(null)

  useEffect(() => {
    if (
      media &&
      mediaState !== media &&
      mediaState.isConnected !== isConnected
    ) {
      setIsConnected(mediaState.isConnected)
      let session_id = sessionId
      if (!sessionId) {
        session_id = uuidv4()
        setSessionId(session_id)
      }
      if (is_controlled) {
        dispatch(
          setLiveManagementSession({
            session_id: session_id,
            avatar_display_name: selectedVoice.voice.display_name,
            avatar_id: selectedVoice.voice?.avatar_identifier,
            is_started: mediaState.isConnected,
            is_recording: mediaState.shouldBeRecorded,
            available_devices: availableDevices,
          }),
        )
      }
      setMedia(mediaState)
    }
    if (!media) {
      setMedia(mediaState)
    }
  }, [mediaState])

  const bindMediaPipe = () => {
    const inputAudioContext = new AudioContext({
      sampleRate: 16_000,
    })

    if (!selectedDevices.input_device) {
      dispatch(updateMediaState({ isConnecting: false }))
      return
    }

    dispatch(
      setLiveSession({
        ...liveSessionData,
        session_id: sessionId,
      }),
    )

    const inputStreamPromise = buildStream(
      metadata,
      is_controlled,
      mediaState,
      custom_inference_url,
      toast,
      dispatch,
      selectedDevices.input_device,
      selectedDevices.output_device,
      inputAudioContext,
      sessionId,
      selectedVoice.voice.data_center_id,
      user_data,
      selectedVoice.voice.latency,
      selectedVoice.voice.key,
      selectedVoice.voice.model_id,
      selectedVoice.voice.display_name,
      deviceTest.isOpen ? () => {} : onOpenSurvey,
      deviceTest,
      obs_sync,
      () => {
        window.clearTimeout(pingDispatcherTimeout)
        setSessionId(uuidv4())
      },
    )

    return () => {
      window.clearTimeout(pingDispatcherTimeout)
      window.clearTimeout(audioCheckDispatcherTimeout)
      window.clearTimeout(audioDispatcherTimeout)
      closeInferenceServiceTransport()
      closePingServiceTransport()

      if (inputAudioContext.state !== 'closed') {
        inputAudioContext?.close()
      }
    }
  }

  useEffect(() => {
    if (
      areAudioPermissions &&
      !mediaState.isConnected &&
      !mediaState.isConnecting
    ) {
      let callback: (() => void) | undefined

      dispatch(
        updateMediaState({
          datacenter: '',
          latency: 0,
          isConnecting: selectedVoice.voice.id !== '',
        }),
      )
      broadcastSystemEvent(
        ESystemMessage.INPUT_DEVICE_SELECTED,
        selectedDevices.input_device,
      )
      broadcastSystemEvent(
        ESystemMessage.OUTPUT_DEVICE_SELECTED,
        selectedDevices.output_device,
      )

      console.log('BIND')
      callback = bindMediaPipe()

      return () => {
        callback?.()
      }
    }
  }, [areAudioPermissions, selectedVoice, selectedDevices])

  const is_controlled = !!features.find(
    (feature) =>
      feature === 'is_controlled_by_live_session_api' ||
      feature === 'is_controlled_by_external_site',
  )
  const refreshCondition = is_controlled && isConnected
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(refreshCondition)
  const user_data = useAppSelector((state) => state.user)

  const { potential_issues, can_show_quality_survey, logout_url } = user_data

  useEffect(() => {
    if (should_show_device_test || is_first_login) {
      dispatch(updateDeviceTest({ isOpen: true }))
    }
    broadcastSystemEvent(
      ESystemMessage.AVAILABLE_AVATARS,
      user_data.s3_models.reduce<string[]>((prev, curr) => {
        return [
          ...prev,
          ...curr.voices
            .map((voice) => voice.avatar_identifier as string)
            .filter((voice) => voice != undefined),
        ]
      }, []),
    )
  }, [])

  return (
    <>
      {!is_controlled && deviceTest.isOpen && canShowDeviceTest && (
        <DeviceTestModal
          isFirstLogin={is_first_login}
          carousel={carousel}
          deviceTest={deviceTest}
          onClose={(withoutSavingChanges) => {
            dispatch(updateDeviceTest({ isOpen: false }))
            if (!withoutSavingChanges) {
              dispatch(setDeviceTest())
            }
          }}
        />
      )}
      {!is_controlled &&
        can_show_quality_survey &&
        !deviceTest.isOpen &&
        potential_issues.length !== 0 && (
          <ModalSurvey isOpenSurvey={isOpenSurvey} onClose={onCloseSurvey} />
        )}
      <Flex w="100%" gap="5">
        <PreventChangePath
          refreshCondition={refreshCondition}
          isOpen={showPrompt}
          onConfirm={confirmNavigation}
          onCancel={cancelNavigation}
        />
      </Flex>
      <>{children}</>
    </>
  )
}

export default LiveSession
