export enum ESessionFilterType {
  users = 'users',
  showroom_categories = 'showroom_categories',
  avatars = 'avatars',
  accounts = 'accounts',
  annotation_types = 'annotation_types',
  scores = 'scores',
}

export enum EDataType {
  metrics = 'metrics',
  table = 'list',
}

export enum ESessionSortType {
  asc = 'asc',
  desc = 'desc',
}

export interface ISessionRequest {
  search?: string
  created_at?: {
    from?: string
    to?: string
  }
  filters?: Record<keyof typeof ESessionFilterType, string[]>
  data_type?: EDataType
  facets?: Record<keyof typeof ESessionFilterType, { search: string }>
  sort?: Record<keyof typeof ESessionFilterType, ESessionSortType>
  page_size?: number
  min_duration?: number
  max_duration?: number
  min_avg_latency?: number
  max_avg_latency?: number
}

export interface ISessionResponseConversionAvatar {
  id: string
  name: string
  model: string
  description: string
}

export interface ISessionResponseUser {
  id: string
  first_name: string
  last_name: string
  username: string
  email: string
}

export interface ISessionResponseConversion {
  id: string
  name: string
  state: string
  avatar: ISessionResponseConversionAvatar
  metadata: { [index: string]: string | number | boolean }
  sample_rate: number
  created_at: string
  updated_at: string
  download_url: string
}

export interface ISessionResponseFacet {
  name: string
  value: string
  count: number
}

export interface ISessioNresponseAccount {
  id: string
  name: string
}

export interface ISessionResponseResult {
  id: string
  categories: string[]
  updated_at: string
  created_at: string
  state: string
  name: string
  survey: {
    answer: string
    issue?: {
      description: string
    }
    score: number
  }
  description: string
  download_url: string
  user: ISessionResponseUser
  account: ISessioNresponseAccount
  metadata: { [index: string]: string | number | boolean }
  showroom_categories: string[]
  duration: number
  conversions: ISessionResponseConversion[]
}

export interface ISessionsResponse {
  results: ISessionResponseResult[]
  facets: Record<keyof typeof ESessionFilterType, ISessionResponseFacet[]>
  body: ISessionRequest
  count: number
  next: string | null
  previous: string | null
  allowed_page_sizes: number[]
  current_page: number
  max_page: number
  table_info?: string
}

export interface IMetricsListItem {
  name: string
  duration: number
  sessions_amount: number
}

export interface IMetricsLatency {
  name: string
  session_count: number
  value: number
  average_latency: number
}

export interface IMetricsLatencyPerAgent {
  name: string
  date: Date
  granularity: string
  average_latency: number
}

export interface IMetrics {
  time_all_for_sessions: number
  talk_time_all_for_sessions: number
  total_amount_of_sessions: number
  total_amount_of_used_avatars_time: number
  total_for_session_per_agent: IMetricsListItem[]
  total_for_latency_per_agent: IMetricsLatency[]
  total_for_session_per_account?: IMetricsListItem[]
  total_for_session_per_agent_per_time: {
    [key: string]: IMetricsListItem[]
  }
  parallel_recordings_per_hour: {
    [key: string]: { id: string; max_overlap: number }[]
  }
  total_for_session_per_account_per_time?: {
    [key: string]: IMetricsListItem[]
  }
  total_for_latency_per_account?: {
    [key: string]: IMetricsLatencyPerAgent[]
  }
  total_score_for_session_per_user_per_time: {
    [key: string]: IMetricsListItem[]
  }
  total_amount_of_used_avatars: number
  avatars_total_time_per_agents: {
    [key: string]: {
      [key: string]: IMetricsListItem
    }
  }
  avatars_total_time_per_account: {
    [key: string]: {
      [key: string]: IMetricsListItem
    }
  }
}

export interface ISessionSliceState {
  response: ISessionsResponse
  metrics: IMetrics
  isLoading: boolean
  isLoadingMetrics: boolean
  usedQuery: any
}
