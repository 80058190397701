import { createSlice } from '@reduxjs/toolkit'
import {
  setIsLoadingSessions,
  hydrateSessions,
  hydrateMetrics,
  setIsLoadingMetrics,
  setUsedQuery,
  clearTable,
} from './actions'
import { ISessionSliceState } from '../../models/sessions/sessions'

export const emptyState: ISessionSliceState = {
  response: {
    table_info: '',
    results: [],
    count: 0,
    facets: {
      users: [],
      showroom_categories: [],
      avatars: [],
      accounts: [],
      annotation_types: [],
      scores: [],
    },
    body: {},
    next: null,
    previous: null,
    allowed_page_sizes: [],
    current_page: 1,
    max_page: 1,
  },
  metrics: {
    time_all_for_sessions: 0,
    talk_time_all_for_sessions: 0,
    total_for_session_per_agent: [],
    total_for_latency_per_agent: [],
    total_for_latency_per_account: {},
    parallel_recordings_per_hour: {},
    total_for_session_per_account: [],
    total_for_session_per_agent_per_time: {},
    total_for_session_per_account_per_time: {},
    total_amount_of_sessions: 0,
    total_amount_of_used_avatars: 0,
    avatars_total_time_per_agents: {},
    avatars_total_time_per_account: {},
    total_amount_of_used_avatars_time: 0,
    total_score_for_session_per_user_per_time: {},
  },
  isLoading: false,
  usedQuery: {},
  isLoadingMetrics: true,
}

export const sessionsSlice = createSlice({
  name: 'sessions',
  initialState: emptyState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(hydrateSessions.match, (state, { payload }) => {
      state.response = payload
    })
    builder.addMatcher(setIsLoadingSessions.match, (state, { payload }) => {
      state.isLoading = payload
    })
    builder.addMatcher(hydrateMetrics.match, (state, { payload }) => {
      state.metrics = payload
    })
    builder.addMatcher(setIsLoadingMetrics.match, (state, { payload }) => {
      state.isLoadingMetrics = payload
    })
    builder.addMatcher(setUsedQuery.match, (state, { payload }) => {
      state.usedQuery = payload
    })
    builder.addMatcher(clearTable.match, (state, { payload }) => {
      state.response = emptyState.response
      state.metrics = emptyState.metrics
      state.isLoading = emptyState.isLoading
      state.isLoadingMetrics = emptyState.isLoadingMetrics
    })
  },
})

export const sessionsReducer = sessionsSlice.reducer
