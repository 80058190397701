import {
  Input,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Button,
  useDisclosure,
  ButtonGroup,
  InputGroup,
  InputRightElement,
  InputLeftElement,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { format, startOfDay, subDays } from 'date-fns'
import { CalendarIcon, SmallCloseIcon } from '@chakra-ui/icons'
import { RangeDatepicker } from '../../Datepicker'
import { Column } from 'react-table'
import { endOfDay } from 'date-fns'

export const RangeDatesFilter = ({
  column,
  usedDates,
  isLoading,
  isClearing,
}: {
  column: Column
  usedDates: any
  isLoading: boolean
  isClearing?: boolean
}) => {
  const [dates, setDates] = React.useState<any>([])
  const {
    isOpen: isOpenDatepicker,
    onToggle: onToggleDatepicker,
    onClose: onCloseDatepicker,
  } = useDisclosure()

  const timerInstance = ((column as any)?.columns as any)?.filter(
    ({ filterType }: any) => filterType === 'datepicker',
  )
  useEffect(() => {
    if (timerInstance[0]?.filterValue) {
      setDates(
        timerInstance[0]?.filterValue ? timerInstance[0]?.filterValue : [],
      )
    }
  }, [])

  const [isFirstDates, setIsFirstDates] = useState(true)

  useEffect(() => {
    if (isFirstDates && !timerInstance[0]?.filterValue && !isLoading) {
      if (usedDates) {
        timerInstance[0]?.setFilter(
          {
            start: new Date(usedDates.from_),
            end: new Date(usedDates.to_),
          } || undefined,
        )
        setDates(
          { start: new Date(usedDates.from_), end: new Date(usedDates.to_) } ||
            undefined,
        )
      }

      setIsFirstDates(false)
    }
  }, [usedDates, isLoading])

  if (timerInstance.length === 0) {
    return <></>
  } else {
    const filterDates = () => {
      onCloseDatepicker()
      timerInstance[0]?.setFilter(
        { start: dates?.start, end: dates?.end } || undefined,
      )
    }

    const data = (dateFormat: string) =>
      timerInstance[0]?.filterValue?.start && timerInstance[0]?.filterValue?.end
        ? `${format(
            new Date(timerInstance[0].filterValue?.start),
            dateFormat ? dateFormat : 'MM/dd/yyyy',
          )}-${format(
            new Date(timerInstance[0].filterValue?.end),
            dateFormat ? dateFormat : 'MM/dd/yyyy',
          )}`
        : ''

    const today = endOfDay(new Date())
    const thirtyDaysAgo = startOfDay(subDays(today, 30))

    const onClearDate = () => {
      // TODO: Fix date component state handling - this should be a delete
      timerInstance[0]?.setFilter({
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
      })
      setDates({ start: startOfDay(new Date()), end: endOfDay(new Date()) })
    }

    useEffect(() => {
      if (isClearing && isLoading) {
        onClearDate()
      }
    }, [isClearing, isLoading])

    const instanceStartTime = startOfDay(
      timerInstance[0]?.filterValue?.start?.getTime(),
    )

    const instanceEndOfDayTime = endOfDay(
      timerInstance[0]?.filterValue?.end?.getTime(),
    )

    return (
      <Popover
        isOpen={isOpenDatepicker}
        onClose={() => {
          if (timerInstance[0].filterValue) {
            setDates(timerInstance[0].filterValue)
          } else {
            setDates([new Date(), new Date()])
          }
          onCloseDatepicker()
        }}
        placement="bottom-end"
      >
        <PopoverTrigger>
          <InputGroup w="2xs">
            <InputLeftElement pointerEvents="auto">
              <CalendarIcon color="#a0aec0" boxSize="5" />
            </InputLeftElement>
            <Input
              onClick={() => {
                onToggleDatepicker()
              }}
              value={data(timerInstance[0]?.inputDateFormat)}
              fontSize="sm"
              onChange={() => {
                return
              }}
              bg="white"
              placeholder="Date"
              data-qa__id="datepicker_input"
            />
            {data(timerInstance[0]?.dateFormat) &&
              (instanceStartTime.toISOString() !==
                startOfDay(new Date()).toISOString() ||
                instanceEndOfDayTime.toISOString() !== today.toISOString()) && (
                <InputRightElement
                  cursor="pointer"
                  width="15%"
                  bg="transparent"
                  borderTopEndRadius="lg"
                  borderBottomEndRadius="lg"
                  onClick={onClearDate}
                >
                  <SmallCloseIcon />
                </InputRightElement>
              )}
          </InputGroup>
        </PopoverTrigger>
        <PopoverContent minW="700px" zIndex="100">
          <PopoverBody width="100%" padding="0">
            <RangeDatepicker
              isAddedConvenientFilters={true}
              selectedDates={
                dates.end
                  ? [dates.start, dates.end]
                  : dates.start
                  ? [dates.start]
                  : []
              }
              onDateChange={(value) => {
                setDates({
                  start: value[0] ? value[0] : null,
                  end: value[1] ? value[1] : null,
                })
              }}
              children={
                <ButtonGroup
                  p="0 0.75rem"
                  mt="0.75rem"
                  isDisabled={!dates.start || !dates.end}
                  justifyContent="flex-end"
                  display="flex"
                  onClick={() => {
                    filterDates()
                  }}
                >
                  <Button
                    variant="primary"
                    data-qa__id="datepicker_filter_button"
                  >
                    Filter
                  </Button>
                </ButtonGroup>
              }
            />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    )
  }
}
