import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  closePingServiceTransport,
  openPingServiceTransport,
} from '../../utils/audioUtils'

import regions from '../Sessions/regions.json'
import { v4 as uuidv4 } from 'uuid'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Text,
} from '@chakra-ui/react'
import Chart from 'chart.js/auto'
import 'chartjs-adapter-date-fns'
import Annotation from 'chartjs-plugin-annotation'
import { blueScroll } from '../../utils/scroll'
Chart.register(Annotation)

const timeForTest = 300000
let pingDispatcherTimeout = 0

const buildPingStream = async (
  custom_inference_url: string,
  assignLatency: (latency: number) => void,
  assignDatacenter: (datacenter: string) => void,
  setErrorElement: ({ header, text }: { header: string; text: string }) => void,
) => {
  window.clearTimeout(pingDispatcherTimeout)

  let pingTransportReader: any = null
  let pingTransportWriter: any = null

  try {
    const { pingReader, pingWriter } = await openPingServiceTransport(
      uuidv4(),
      custom_inference_url,
      'meaningvc',
      () => {
        pingTransportReader = null
        pingTransportWriter = null
        closePingServiceTransport()
      },
      (err: any) => {
        if (err.message && err.name) {
          setErrorElement({ text: err.message, header: err.name })
        }
        pingTransportReader = null
        pingTransportWriter = null
        closePingServiceTransport()
      },
    )
    pingTransportReader = pingReader
    pingTransportWriter = pingWriter
  } catch (err: any) {
    if (err.message && err.name) {
      setErrorElement({ text: err.message, header: err.name })
    }
    console.log('err', err)
  }

  let pingLatency = 0
  const pingDispatcher = async () => {
    window.clearTimeout(pingDispatcherTimeout)
    pingDispatcherTimeout = window.setTimeout(pingDispatcher, 1000)
    if (pingTransportReader && pingTransportWriter) {
      const buffer = new ArrayBuffer(16)
      const view = new DataView(buffer)

      view.setBigUint64(0, BigInt(new Date().getTime()), false)
      view.setBigUint64(8, BigInt(pingLatency), false)

      const uint8Array = new Uint8Array(buffer)
      pingTransportWriter.write(uint8Array)

      const { value, done } = await pingTransportReader.read().catch(() => {
        return { value: null, done: true }
      })

      if (!value || done) {
        pingLatency = 0
        return
      }

      const dataView = new DataView(new Uint8Array(value).buffer)
      const receivedTimestamp = Number(dataView.getBigUint64(8, true))
      const datacenterID = Number(dataView.getBigUint64(16, true))

      pingLatency = Math.abs(receivedTimestamp - new Date().getTime())

      const regionKeys = Object.keys(regions) as string[]
      const regionKey = regionKeys[datacenterID - 1] as keyof typeof regions

      let datacenter = ''
      if (regionKey && regions[regionKey]) {
        datacenter = regions[regionKey].name
      } else {
        console.log('Datacenter ID does not correspond to any region.')
      }
      assignLatency(pingLatency)
      assignDatacenter(datacenter)
    }
  }

  try {
    pingDispatcher()
  } catch {}
}

export const PingTest = ({ chartInstanceRef }: { chartInstanceRef: any }) => {
  const [pingLatencyArr, setPingLatencyArr] = useState<
    { date: Date; ping: number }[]
  >([])
  const [datacenter, setDatacenter] = useState<string>('')
  const [errorList, setErrorsList] = useState<
    { text: string; header: string }[]
  >([])
  const inferenceUrl = process.env.REACT_APP_INFERENCE_URL || ''
  const chartRef = useRef<HTMLCanvasElement | null>(null)

  const startPingServer = () => {
    buildPingStream(
      inferenceUrl,
      (ping) => {
        setPingLatencyArr((prevLatencyArr) => [
          ...prevLatencyArr,
          { date: new Date(), ping },
        ])
      },
      (datacenter) => {
        setDatacenter(datacenter)
      },
      (err) => {
        setErrorsList((errListElements) => [...errListElements, err])
        console.log('err', err)
      }, // assign error
    )
  }

  useEffect(() => {
    if (!chartRef.current) return

    const formattedData = pingLatencyArr.map((item) => ({
      x: new Date(item.date),
      y: item.ping,
    }))
    if (chartInstanceRef.current) {
      chartInstanceRef.current.data.datasets[0].data = formattedData
      chartInstanceRef.current.update()
    } else {
      chartInstanceRef.current = new Chart(chartRef.current, {
        type: 'line',
        plugins: [
          {
            id: 'afterLayout3Colors',
            afterLayout: (chart) => {
              let ctx = chart.ctx
              ctx.save()

              let yAxis = chart.scales.y

              let yThreshold100 = yAxis.getPixelForValue(100)
              let yThreshold200 = yAxis.getPixelForValue(200)

              let gradient = ctx.createLinearGradient(
                0,
                yAxis.top,
                0,
                yAxis.bottom,
              )

              let offset100 =
                (yThreshold100 - yAxis.top) / (yAxis.bottom - yAxis.top)
              let offset200 =
                (yThreshold200 - yAxis.top) / (yAxis.bottom - yAxis.top)

              gradient.addColorStop(0, 'red')
              gradient.addColorStop(offset200, 'red')
              gradient.addColorStop(offset200, '#FFCC00')
              gradient.addColorStop(offset100, '#FFCC00')
              gradient.addColorStop(offset100, 'green')
              gradient.addColorStop(1, 'green')

              chart.data.datasets[0].borderColor = gradient

              ctx.restore()
            },
          },
        ],
        data: {
          datasets: [
            {
              borderWidth: 1,
              label: 'Latency Over Time',
              data: formattedData,
              backgroundColor: 'rgba(75, 192, 192, 0.2)',
              tension: 0.1,
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: false,
              position: 'top',
            },
            annotation: {
              annotations: [
                {
                  type: 'line',
                  yMin: 100,
                  yMax: 100,
                  borderColor: '#FFCC00',
                  borderWidth: 2,
                  label: {
                    content: '100 ms',
                  },
                },
                {
                  type: 'line',
                  yMin: 200,
                  yMax: 200,
                  borderColor: 'red',
                  borderWidth: 2,
                  label: {
                    content: '100 ms',
                  },
                },
              ],
            },
          },
          scales: {
            x: {
              type: 'time',
              time: {
                unit: 'second',
              },
              title: {
                display: true,
                text: 'Time',
              },
            },
            y: {
              title: {
                display: true,
                text: 'Latency (ms)',
              },
              suggestedMin: 0,
              suggestedMax: 250,
            },
          },
        },
      })
    }
  }, [pingLatencyArr])

  useEffect(() => {
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy()
        chartInstanceRef.current = null
      }
    }
  }, [])

  useEffect(() => {
    startPingServer()
    const timeout = setTimeout(() => {
      closePingServiceTransport()
      console.log('Ping service transport closed after 5 minutes')
    }, timeForTest)

    return () => {
      closePingServiceTransport()
      clearTimeout(timeout)
    }
  }, [])

  const chart = useMemo(() => {
    return (
      <canvas
        ref={chartRef}
        id="latencyChart"
        style={{ width: '100%', maxHeight: '250px' }}
      ></canvas>
    )
  }, [])

  return (
    <Box>
      <Text fontSize="15px" fontWeight="semibold">
        Datacenter
      </Text>
      <Flex height="20px" alignItems="center">
        <Text fontSize="13px" fontWeight="normal">
          {datacenter}
        </Text>
      </Flex>
      <Box mt={3} mb={3}>
        <Text fontSize="15px" fontWeight="semibold">
          Latency Chart
        </Text>
        <Box mt="5" w="100%" h="250px" maxHeight="250px">
          {chart}
        </Box>
      </Box>
      {errorList.length > 0 && (
        <Box mt={3}>
          <Text mb={3} fontSize="15px" fontWeight="semibold">
            Ping Information
          </Text>
          <Box
            w="100%"
            maxHeight="150px"
            overflow="scroll"
            overflowX="auto"
            css={blueScroll}
          >
            <Accordion allowMultiple>
              {errorList.map(({ text, header }, index) => {
                return (
                  <AccordionItem key={`${index}_${header}`}>
                    <h2>
                      <AccordionButton>
                        <Box as="span" flex="1" textAlign="left">
                          {header}
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel fontSize="13px" pb={4}>
                      {text}
                    </AccordionPanel>
                  </AccordionItem>
                )
              })}
            </Accordion>
          </Box>
        </Box>
      )}
    </Box>
  )
}
